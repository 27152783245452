
import {createVuetify} from 'vuetify';
import type {ThemeDefinition} from 'vuetify';
import * as labsComponents from 'vuetify/labs/components'
import {aliases as iconAliases, mdi} from 'vuetify/iconsets/mdi';
import {md} from "vuetify/iconsets/md";

import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/scss/main.scss';
import {createVueI18nAdapter} from "vuetify/locale/adapters/vue-i18n";

import {i18n} from "@/plugins/i18n";
import {useI18n} from "vue-i18n";
import {VTextField} from "vuetify/components/VTextField";
import {VBtn} from "vuetify/components/VBtn";

const icons = {
    defaultSet: 'mdi',
    aliases: iconAliases,
    sets: { mdi, md }
}

const light: ThemeDefinition = {
    dark: false,
    colors: {
        primary: '#00C8BC',
        'on-primary': '#ffffff',
        secondary: '#6E91EF',
        'on-secondary': '#ffffff',
        tertiary: '#b79683',
        'on-tertiary': '#ffffff',
        background: '#F6F6F6',
        surface: '#ffffff',
    }
}

const dark: ThemeDefinition = {
    dark: true,
    colors: {
        primary: '#00C8BC',
        'on-primary': '#ffffff',
        secondary: '#6E91EF',
        'on-secondary': '#ffffff',
        tertiary: '#b79683',
        'on-tertiary': '#ffffff',
        background: '#171C23',
        surface: '#212832'
    }
}

const aliases = {
    SearchField: VTextField,
    BtnPrimary: VBtn,
    BtnSecondary: VBtn,
    BtnDanger: VBtn,
    BtnWarning: VBtn,
    BtnBase: VBtn,
    BtnText: VBtn,
}

const defaults = {
    VTextField: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
        baseColor: 'rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity))'
    },
    VNumberInput: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
        controlVariant: 'stacked'
    },
    VDateInput: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
    },
    VTextarea: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
        baseColor: 'rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity))',
        rows: 3
    },
    VCombobox: {
      variant: 'outlined',
      density: 'comfortable',
      color: 'primary',
      hideDetails: 'auto',
    },
    VSelect: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
    },
    VFileInput: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
    },
    VTabs: {
        color: 'primary'
    },
    VAutocomplete: {
        variant: 'outlined',
        density: 'comfortable',
        color: 'primary',
        hideDetails: 'auto',
        baseColor: 'rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity))'
    },
    SearchField: {
        variant: 'outlined',
        color: 'primary',
        baseColor: 'rgba(var(--v-theme-on-surface), var(--v-medium-emphasis-opacity))',
        density: 'compact',
        prependInnerIcon: 'mdi-magnify',
        hideDetails: true,
        clearable: true
    },
    VField: {
      variant: 'outlined',
      color: 'primary'
    },
    BtnPrimary: {
        variant: 'tonal',
        color: 'primary'
    },
    BtnSecondary: {
        variant: 'tonal',
        color: 'secondary'
    },
    BtnDanger: {
        variant: 'tonal',
        color: 'error'
    },
    BtnWarning: {
        variant: 'tonal',
        color: 'orange'
    },
    BtnText: {
      variant: 'text'
    },
    VCard: {
      rounded: 'lg'
    },
    VToolbar: {
        color: 'transparent',
    },
    VSimpleCheckbox: {
        color: 'primary'
    },
    VSwitch: {
      color: 'primary',
      density: 'compact',
      hideDetails: true
    },
    VAlert: {
      rounded: 'lg'
    },
    VDataTableServer: {
        hover: true,
        itemValue: 'id',
        showSelect: true,
        itemsPerPage: 25,
        itemsPerPageOptions: [
            {value: 25, title: '25'},
            {value: 50, title: '50'},
            {value: 100, title: '100'},
        ]
    }
}

export const vuetify = createVuetify({
    locale: {
      adapter: createVueI18nAdapter({i18n, useI18n})
    },
    components: {...labsComponents},
    icons,
    aliases,
    defaults,
    theme: {
        themes: {
            light,
            dark
        }
    }
})
