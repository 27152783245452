import type { RouteRecordRaw } from 'vue-router'

export const pageRoutes: RouteRecordRaw[] = [
  {
    path: 'pages',
    name: 'pages.index',
    component: () => import('@/modules/app/views/PagesIndex.vue'),
    redirect: { name: 'pages.lastModified' },
    children: [
      {
        path: '',
        name: 'pages.lastModified',
        component: () => import('@/modules/app/views/PagesLastModified.vue')
      },
      {
        path: ':pageId',
        name: 'page.show',
        component: () => import('@/modules/app/views/AppPageIndex.vue'),
        props: true,
        redirect: { name: 'page.details' },
        children: [
          {
            path: '',
            name: 'page.details',
            component: () => import('@/modules/app/views/AppPage.vue')
          },
          {
            path: 'update',
            name: 'page.update',
            component: () => import('@/modules/app/views/AppPageUpdate.vue'),
            redirect: { name: 'page.update.contents' },
            children: [
              {
                path: 'contents',
                name: 'page.update.contents',
                component: () => import('@/modules/app/views/AppPageContentsView.vue')
              },
              {
                path: 'translations',
                name: 'page.update.translations',
                component: () => import('@/modules/app/views/AppPageTranslationsView.vue')
              },
              {
                path: 'settings',
                name: 'page.update.settings',
                component: () => import('@/modules/app/views/AppPageSettingsView.vue')
              }
            ]
          },
          {
            path: 'history',
            name: 'page.history',
            component: () => import('@/modules/app/views/PageHistory.vue')
          }
        ]
      }
    ]
  }
]
