<script setup lang="ts">
import {useIsAuthorized} from "@/modules/authorization/composables/is-authorized";
import {RoleType} from "@/modules/authorization/utils/role-type.enum";

const {isAuthorized} = useIsAuthorized([RoleType.SuperAdmin])
</script>

<template>
  <slot v-if="isAuthorized" />
</template>
