import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'
import { setErrorMap } from 'zod'
import { makeZodI18nMap } from 'zod-vue-i18n'
import { cookieStorage } from '@/utils/cookies.utils'

const en = (await import(`@/locales/en-US.json`)).default
const fallbackLocale = 'en-US'

let messages = {
  [fallbackLocale]: en
} as any

const locale = cookieStorage.getItem('hsp-lang') ?? 'en-US'

if (locale !== fallbackLocale) {
  try {
    messages = {
      ...messages,
      [locale]: (await import(`@/locales/${locale}.json`)).default
    }
  } catch (e) {
    console.error(e)
  }
}

export const availableLocales = [
  {
    code: 'de-DE',
    name: 'Deutsch',
    flag: ''
  },
  {
    code: 'en-US',
    name: 'English',
    flag: ''
  }
]

export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale,
  messages
})

setErrorMap(makeZodI18nMap(i18n))

export const setLocale = async (locale: string) => {
  i18n.global.locale.value = locale
  cookieStorage.setItem('hsp-lang', locale)
  await loadLocaleMessages(locale)
}

export const loadLocaleMessages = async (locale: string) => {
  const messages = await import(`@/locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages.default)
  return nextTick()
}
