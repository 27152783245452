<script setup lang="ts">
import {useCurrentUserStore} from "@/stores/current-user";
import {storeToRefs} from "pinia";
import {computed} from "vue";

const currentUserStore = useCurrentUserStore();
const {initials} = storeToRefs(currentUserStore);

const icon = computed(() => {
  return !initials.value ? 'mdi-account-circle-outline' : undefined;
});

const text = computed(() => {
  return initials.value ? initials.value : undefined;
})

</script>

<template>
  <v-avatar color="primary" :icon="icon" :text="text" />

</template>

<style scoped lang="scss">

</style>
