<template>
  <v-btn data-cy-btn-login variant="plain" prepend-icon="mdi-login-variant" @click="signIn">
    Anmelden
  </v-btn>
</template>

<script setup lang="ts">
import {useAuthStore} from "@/modules/authentication/stores/auth";
const {signIn} = useAuthStore();
</script>
