<template>
<v-btn variant="plain" :icon="icon" @click="toggle"></v-btn>
</template>

<script setup lang="ts">

import {computed} from "vue";
import {useThemeStore} from "@/stores/theme";
import {storeToRefs} from "pinia";

const themeStore = useThemeStore();
const {toggle} = themeStore;
const {isDarkTheme} = storeToRefs(themeStore);
const icon = computed(() => isDarkTheme.value ? 'mdi-weather-sunny' : 'mdi-weather-night');

</script>
