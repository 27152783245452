import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getUniqueIdentifier } from '@/utils/identifier'
import { i18n } from '@/plugins/i18n'

type Snackbar = {
  id: string
  message: string
  icon: string
  color?: string
  timeout?: number
  show: boolean
}

export const useSnackbarStore = defineStore('snackbar', () => {
  const snackbars = ref<Snackbar[]>([])

  const showSnackbar = ({
    message,
    timeout = 6000,
    color,
    icon
  }: Omit<Snackbar, 'id' | 'show'>) => {
    const snackbar = {
      id: getUniqueIdentifier(),
      message,
      color,
      timeout,
      icon,
      show: true
    }

    snackbars.value.push(snackbar)

    setTimeout(() => closeSnackbar(snackbar.id), timeout)
  }

  const closeSnackbar = (snackbarId: string) => {
    snackbars.value = snackbars.value.filter((snackbar) => snackbar.id !== snackbarId)
  }

  const warn = (message: string) => {
    showSnackbar({
      message,
      color: 'warning',
      icon: 'mdi-alert-outline'
    })
  }

  const error = (message?: string) => {
    showSnackbar({
      //@ts-ignore
      message: message ?? i18n.global.t('general.notifications.error'),
      color: 'error',
      icon: 'mdi-alert-circle-outline'
    })
  }

  const success = (message?: string) => {
    showSnackbar({
      //@ts-ignore
      message: message ?? i18n.global.t('general.notifications.success'),
      color: 'success',
      icon: 'mdi-check-circle-outline'
    })
  }

  const info = (message: string) => {
    showSnackbar({
      message,
      color: 'info',
      icon: 'mdi-information-outline'
    })
  }

  return {
    snackbars,
    warn,
    error,
    success,
    info,
    closeSnackbar
  }
})
