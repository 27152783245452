import { RoleType } from '@/modules/authorization/utils/role-type.enum'
import { ref } from 'vue'
import { useCurrentUserStore } from '@/stores/current-user'
import { storeToRefs } from 'pinia'
import { watchImmediate } from '@vueuse/core'

export const useIsAuthorized = (authorizedRoles: RoleType[] = [RoleType.SuperAdmin]) => {
  const currentUserStore = useCurrentUserStore()
  const { roles } = storeToRefs(currentUserStore)
  const isAuthorized = ref(false)

  watchImmediate(
    () => roles.value,
    (value) => {
      isAuthorized.value =
        value.find(({ name }) => authorizedRoles.includes(name as RoleType)) !== undefined
    }
  )

  return {
    isAuthorized
  }
}
