<script setup lang="ts">

import {useAuthStore} from "@/modules/authentication/stores/auth";
import {onMounted, onUnmounted, ref} from "vue";
import {useRouter} from "vue-router";
import AppLogo from "@/components/AppLogo.vue";

const {signinRedirectCallback} = useAuthStore();
const router = useRouter();
const isOverlayOpen = ref(true);

onMounted(async () => {

  try {
    const user = await signinRedirectCallback();
    const redirect = user.state ? (user.state as any).to : null;
    await router.replace(redirect ?? '/');

  } catch (e) {
    await router.replace('/');
  }
})

onUnmounted(() => {
  isOverlayOpen.value = false;
})

</script>

<template>
  <v-overlay :scrim="false" persistent class="bg-surface d-flex align-center justify-center" v-model="isOverlayOpen">
    <v-empty-state class="ga-2">
      <template #media>
        <v-icon size="54" :icon="AppLogo" />
      </template>
      <template #title>
        <div class="text-h3">
          Health Study Project
        </div>
      </template>
      <v-responsive width="300">
        <v-progress-linear class="w-100" color="primary" indeterminate />
      </v-responsive>
      <div class="text-center pa-2">Signing in...</div>
    </v-empty-state>
  </v-overlay>
</template>

<style  lang="scss">

</style>
