<script setup lang="ts">
import {useSnackbarStore} from "@/stores/snackbar";
import {storeToRefs} from "pinia";

const snackbarStore = useSnackbarStore();
const {snackbars} = storeToRefs(snackbarStore);
</script>

<template>
  <div class="snackbars">
    <v-snackbar width="320" location="bottom right" v-for="snackbar in snackbars" :key="snackbar.id" :timeout="snackbar.timeout" :color="snackbar.color" v-model="snackbar.show">
      <div class="d-flex align-center">
        <v-icon :icon="snackbar.icon" />
        <span class="ml-2">{{snackbar.message}}</span>
      </div>
    </v-snackbar>
  </div>

</template>

<style scoped lang="scss">

</style>
